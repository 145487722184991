// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import React, {Component} from 'react';
import './App.css';
import {BrowserRouter as Router, Route, RouteComponentProps, Switch} from 'react-router-dom';
import EventList from './event/EventList';
import {MultiballEvent} from "./model/MultiballEvent";
import AppNavbar from "./AppNavbar";
import ParticipantList from "./participant/ParticipantList";
import GameList from "./game/GameList";
import {Container, Toast, ToastBody, ToastHeader} from "reactstrap";
import {Alert} from "./Alert";
import LoginComponent from "./login/LoginComponent";
import AuthenticatedRoute from "./login/AuthenticatedRoute";
import AuthenticationService from "./service/AuthenticationService";
import ForgotPasswordComponent from "./login/ForgotPasswordComponent";
import ChangePasswordComponent from "./login/ChangePasswordComponent";
import {MultiballGame} from "./model/MultiballGame";
import {ExtractRouteParams} from "react-router";
import logo from "./images/mdsz.png";

interface State {
    selectedEvent: MultiballEvent | null,
    selectedGame: MultiballGame | null,
    alerts: Array<Alert>,
    loggedIn: boolean
}

class App extends Component<any, State> {

    alertCounter = 0;

    constructor(props: never) {
        super(props);
        try {
            const item = window.sessionStorage.getItem("selectedEvent")
            const gameItem = window.sessionStorage.getItem("selectedGame")
            this.state = {
                selectedEvent: item ? JSON.parse(item) : null,
                selectedGame: gameItem ? JSON.parse(gameItem) : null,
                alerts: [],
                loggedIn: AuthenticationService.isUserLoggedIn()
            };
        } catch (error) {
            this.state = {
                selectedEvent: null,
                selectedGame: null,
                alerts: [],
                loggedIn: AuthenticationService.isUserLoggedIn()
            };
        }


        this.selectEvent = this.selectEvent.bind(this);
    }

    selectEvent(evt: MultiballEvent): void {
        this.setState({selectedEvent: evt}, () => {
            try {
                window.sessionStorage.setItem("selectedEvent", JSON.stringify(evt))
            } catch (error) {
            }
        });
    }

    eventDeleted(id: string): void {
        if(this.state.selectedEvent != null && this.state.selectedEvent.id == id){
            this.setState({selectedEvent: null}, () => {
                try {
                    window.sessionStorage.removeItem("selectedEvent")
                } catch (error) {
                }
            });
        }
    }

    selectGame(evt: MultiballGame | null): void {
        this.setState({selectedGame: evt}, () => {
            try {
                if (evt == null) {
                    window.sessionStorage.removeItem("selectedGame")
                } else {
                    window.sessionStorage.setItem("selectedGame", JSON.stringify(evt))
                }
            } catch (error) {
            }
        });
    }

    addAlert(mode: string, title: string, message: string): void {
        const alert = new Alert();
        const alerts = this.state.alerts;
        alert.id = this.alertCounter;
        setTimeout(this.removeAlert.bind(this, alert.id), 5000);
        alert.mode = mode;
        alert.title = title;
        alert.message = message;
        alerts.push(alert);
        this.alertCounter++;
        this.setState({...this.state, alerts: alerts});
    }

    removeAlert(id: number): void {
        const alerts = this.state.alerts;
        this.setState({...this.state, alerts: alerts.filter(alert => alert.id != id)});
    }

    loginSuccess(): void {
        window.location.href = "/";
    }

    logout(): void {
        AuthenticationService.logout();
        this.forceUpdate();
    }

    render(): JSX.Element {
        return (
            <div className={"wrapper"}>
                <Router>

                    {AuthenticationService.isUserLoggedIn() &&
                    <div className={"sidebar"} data-color="orange">
                        <div className={"sidebar-background"}>
                        </div>
                        <div className={"sidebar-wrapper"}>

                            <div className="logo d-flex align-items-center justify-content-center">
                                <a href="/" className="simple-text logo-mini mx-1">
                                    <div className="logo-img"><img
                                        src={logo}
                                        alt="..."/>
                                    </div>
                                </a>
                            </div>

                            <AppNavbar
                                selectedEvent={this.state.selectedEvent}
                                selectedGame={this.state.selectedGame}
                                selectGame={this.selectGame.bind(this)}
                                logout={this.logout.bind(this)}
                            />
                        </div>
                    </div>
                    }
                    <div className={AuthenticationService.isUserLoggedIn() ? "main-panel" : "main-panel-wide"}>
                        <Switch>

                            <Route path="/login" component={
                                () => <LoginComponent history={this.props.history}
                                                      loginSuccess={this.loginSuccess.bind(this)}/>
                            }
                            />
                            <Route path="/changePassword/:passwordStr" render={
                                (props: RouteComponentProps<ExtractRouteParams<string, string>>) => (
                                    <ChangePasswordComponent
                                        passwordStr={props.match.params.passwordStr}
                                        addAlert={this.addAlert.bind(this)}>
                                    </ChangePasswordComponent>
                                )}
                            />
                            <Route path="/changePassword" render={
                                () => (
                                    <ChangePasswordComponent
                                        passwordStr={undefined}
                                        addAlert={this.addAlert.bind(this)}>
                                    </ChangePasswordComponent>
                                )}
                            />

                            <AuthenticatedRoute path='/' exact={true}
                                                component={
                                                    () => <EventList
                                                        selectEvent={this.selectEvent.bind(this)}
                                                        eventDeleted={this.eventDeleted.bind(this)}
                                                        addAlert={this.addAlert.bind(this)}>
                                                    </EventList>
                                                }
                            />
                            <AuthenticatedRoute path='/events' exact={true}
                                                component={
                                                    () =>
                                                        <EventList
                                                            selectEvent={this.selectEvent.bind(this)}
                                                            eventDeleted={this.eventDeleted.bind(this)}
                                                            addAlert={this.addAlert.bind(this)}>
                                                        </EventList>
                                                }
                            />
                            <Route path='/forgot' component={ForgotPasswordComponent}/>
                            <AuthenticatedRoute path='/participants/:eventId'
                                                render={
                                                    (props: RouteComponentProps<ExtractRouteParams<string, string>>) =>
                                                        (
                                                            <ParticipantList
                                                                eventId={props.match.params.eventId == undefined ? null : props.match.params.eventId}
                                                                addAlert={this.addAlert.bind(this)}>
                                                            </ParticipantList>
                                                        )
                                                }
                            />
                            <AuthenticatedRoute path='/games/:eventId'
                                                render={
                                                    (props: RouteComponentProps<ExtractRouteParams<string, string>>) =>
                                                        (
                                                            <GameList
                                                                eventId={props.match.params.eventId == undefined ? null : props.match.params.eventId}
                                                                selectGame={this.selectGame.bind(this)}>
                                                            </GameList>
                                                        )
                                                }
                            />

                        </Switch>
                    </div>

                    <Container style={{position: "absolute", right: 0, top: 0, width: 400}}>
                        {
                            this.state.alerts.map(
                                (alert: Alert) => {
                                    return (
                                        <Toast key={alert.id} fade={true} className={"alert_" + alert.mode}>
                                            <ToastHeader>
                                                {alert.title}
                                            </ToastHeader>
                                            <ToastBody>
                                                {alert.message}
                                            </ToastBody>
                                        </Toast>
                                    );
                                }
                            )
                        }

                    </Container>
                </Router>
            </div>
        )
    }
}

export default App;