import React from 'react'
import AuthenticationService from '../service/AuthenticationService';
import {Card, CardBody, CardTitle, Container, FormGroup, Input, Label} from "reactstrap";
import {Redirect} from "react-router-dom";
import logo from "../images/mdsz.png";

interface State {
    [key: string]: any,

    username: string
}

interface Props {
    history: any,
    loginSuccess: () => void;
}

class ForgotPasswordComponent extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props)

        this.state = {
            username: '',
            hasLoginFailed: false,
            showSuccessMessage: false
        }

        this.handleChange = this.handleChange.bind(this)
        this.sendClicked = this.sendClicked.bind(this)
    }

    handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState(
            {
                ...this.state,
                [event.currentTarget.name]: event.currentTarget.value
            }
        )
    }

    sendClicked(): void {
        AuthenticationService.forgotPassword(this.state.username).then(
            () => window.location.href = "/"
        ).catch( //nem kell semmi üzenet
            () => window.location.href = "/"
        );
    }

    render(): JSX.Element {
        return (!AuthenticationService.isUserLoggedIn() ?

                <Container fluid style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%"
                }}>
                    <Container style={{width: 600, height: 300, display: "flex", marginBottom: 160}}>
                        <div className="logo d-flex align-items-start justify-content-center"
                             style={{width: 150, backgroundColor: "orange", paddingTop: 15}}>
                            <a href="/" className="simple-text logo-mini mx-1">
                                <div className="logo-img"><img
                                    src={logo}
                                    alt="..."/>
                                </div>
                            </a>
                        </div>
                        <Card style={{width: 400}}>
                            <CardTitle>
                                Elfelejtett jelszó
                            </CardTitle>
                            <CardBody>


                                <div className="container">

                                    {this.state.hasLoginFailed &&
                                    <div className="alert alert-warning">Hibás felhasználó név vagy jelszó</div>}
                                    {this.state.showSuccessMessage && <Redirect to='/events'/>}

                                    <FormGroup className="col-md-12 mb-12">
                                        <Label for="name">Felhasználó név</Label>
                                        <Input type="text" name="username" value={this.state.username}
                                               onChange={this.handleChange}
                                               autoComplete="username"
                                        />
                                    </FormGroup>

                                    <FormGroup className="col-md-12 mb-12"
                                               style={{marginTop: 107, textAlign:"right"}}>
                                        <a className={"button"} onClick={this.sendClicked}>
                                            Küldés</a>
                                        <a className={"button"} href="/login">
                                        Mégsem</a>
                                    </FormGroup>

                                </div>

                            </CardBody>
                        </Card>

                    </Container>

                </Container> : <p></p>


        )
    }
}

export default ForgotPasswordComponent