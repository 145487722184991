import React from 'react'
import AuthenticationService from '../service/AuthenticationService';
import {Card, CardBody, CardTitle, Container, FormGroup, Input, Label} from "reactstrap";
import {Redirect} from "react-router-dom";
import logo from "../images/mdsz.png";

interface State {
    [key: string]: any,

    username: string,
    password: string,
    passwordRe: string
}

interface Props {
    passwordStr: string | undefined,
    addAlert: (mode: string, title: string, message: string) => void
}

class ChangePasswordComponent extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            username: AuthenticationService.getLoggedInUserName(),
            password: '',
            passwordRe: ''
        }

        this.handleChange = this.handleChange.bind(this)
        this.changePasswordClicked = this.changePasswordClicked.bind(this)
    }

    handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState(
            {
                ...this.state,
                [event.currentTarget.name]: event.currentTarget.value
            }
        )
    }

    changePasswordClicked(): void {
        AuthenticationService
            .changePassword(this.state.username, this.state.password, this.state.passwordRe, this.props.passwordStr)
            .then(
                () => {
                    window.location.href = "/";
                }
            ).catch(
            () => {
                this.props.addAlert("error", "Hiba történt!", "A jelszó változtatás sikertelen")
            }
        )
    }

    render(): JSX.Element {
        return (
            <Container fluid style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%"
            }}>
                <Container style={{width: 600, height: 300, display: "flex", marginBottom: 160}}>
                    <div className="logo d-flex align-items-start justify-content-center"
                         style={{width: 150, backgroundColor: "orange", paddingTop: 15}}>
                        <a href="/" className="simple-text logo-mini mx-1">
                            <div className="logo-img"><img
                                src={logo}
                                alt="..."/>
                            </div>
                        </a>
                    </div>
                    <Card style={{width: 400}}>
                        <CardTitle>
                            Elfelejtett jelszó
                        </CardTitle>
                        <CardBody>

                            <div className="container" >
                                {this.state.showSuccessMessage && <Redirect to='/events'/>}
                                {!AuthenticationService.isUserLoggedIn() &&
                                <FormGroup className="col-md-12 mb-12">
                                    <Label for="name">Felhasználó név</Label>
                                    <Input type="text" name="username" value={this.state.username}
                                           onChange={this.handleChange}
                                           autoComplete="username"
                                    />
                                </FormGroup>
                                }
                                <FormGroup className="col-md-12 mb-12">
                                    <Label for="password">Jelszó</Label>
                                    <Input type="password" name="password" value={this.state.password}
                                           onChange={this.handleChange}
                                           autoComplete="current-password"
                                    />
                                </FormGroup>
                                <FormGroup className="col-md-12 mb-12">
                                    <Label for="passwordRe">Jelszó újra</Label>
                                    <Input type="password" name="passwordRe" value={this.state.passwordRe}
                                           onChange={this.handleChange}
                                           autoComplete="current-password"
                                    />
                                </FormGroup>
                                <FormGroup className="col-md-12 mb-12">
                                    <a className="button" onClick={this.changePasswordClicked}>Jelszó
                                        megváltoztatása</a>
                                    <a className={"button"} href="/">
                                        Mégsem</a>
                                </FormGroup>
                                {this.state.hasLoginFailed &&
                                <div className="alert alert-warning">Hibás felhasználó név vagy jelszó</div>}

                            </div>

                        </CardBody>
                    </Card>

                </Container>

            </Container>
        )
    }
}

export default ChangePasswordComponent