import React from 'react';
import {Form, FormFeedback, FormGroup, Input, Label} from 'reactstrap';
import "moment/locale/hu";
import "react-datetime/css/react-datetime.css";
import {MultiballParticipant} from "../model/MultiballParticipant";
import {MultiballError} from "../model/MultiballError";
import API from "../service/api";

interface MultiballParticipantValidity {
    id: boolean,
    name: boolean,
    email: boolean,
    nickName: boolean,
    eventId: boolean
}

interface State {
    item: MultiballParticipant;
    validity: MultiballParticipantValidity;
    errors: Errors;
}

interface Props {
    participantId: string | null,
    eventId: string | null
}

interface Errors {
    [key: string]: any,
    name: string | null,
    email: string | null
}

class ParticipantEdit extends React.Component<Props, State> {

    createEmptyErrors(): Errors {
        return {
            name: null,
            email: null
        };
    }

    emptyItem: MultiballParticipant = {
        id: null,
        name: null,
        email: null,
        nickName: null,
        eventId: null
    };


    constructor(props: Props) {
        super(props);
        this.state = {
            item: {...this.emptyItem, eventId: this.props.eventId},
            validity: {id: true, name: true, email: true, nickName: true, eventId: true},
            errors: this.createEmptyErrors()
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    setError(err: MultiballError): void {
        const errors = this.createEmptyErrors();
        err.errorMessage.forEach(value => {
            errors[value.fieldName] = value.messageError;
        })
        this.setState({...this.state, errors: errors})
    }

    async componentDidMount(): Promise<void> {
        if (this.props.participantId !== null) {
            const participant = await (await API.getInstance().get(`/api/participant/${this.props.participantId}`)).data;
            this.setState({item: participant}, () => {
                this.formValidation();
            });
        }
    }

    handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
        const target = event.target;
        const value = target.value;
        const name: string = target.name;
        const item: any = {...this.state.item};
        item[name.toString()] = value;
        this.setState({item}, () => {
            this.formValidation();
        });
    }

    formValidation(): boolean {
        let isValid = true;
        const {item} = this.state;
        const {validity} = this.state;

        if (item.email != null) {
            if (item.email.length > 0) {
                const re = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
                validity.email = re.test(String(item.email).toLowerCase());
                isValid = isValid && validity.email;
            } else {
                validity.email = false;
                isValid = false;
            }
        }
        this.forceUpdate();
        return isValid;
    }

    async handleSubmit(): Promise<boolean> {
        const {item} = this.state;
        let response: any;
        if (item.id) {
            response = await API.getInstance().put('/api/participant/' + item.id, item);
        } else {
            response = await API.getInstance().post('/api/participant', item);
        }
        if (response.status == 200 || response.status == 201) {
            return response.data;
        } else {
            return Promise.reject(response.data);
        }
    }

    render(): JSX.Element {
        const {item} = this.state;

        return <Form>

            <FormGroup>
                <Label for="name">Név</Label>
                <Input invalid={this.state.errors.name !== null}
                       type="text" name="name" id="name" value={item.name || ''}
                       onChange={this.handleChange}/>
                <FormFeedback>{this.state.errors.name}</FormFeedback>
            </FormGroup>
            <FormGroup>
                <Label for="email">E-mail</Label>
                <Input invalid={this.state.errors.email !== null}
                       type="email" name="email" id="email" value={item.email || ''}
                       onChange={this.handleChange}/>
                <FormFeedback>{this.state.errors.email}</FormFeedback>
            </FormGroup>
            <FormGroup>
                <Label for="nickName">Becenév</Label>
                <Input type="text" name="nickName" id="nickName" value={item.nickName || ''}
                       onChange={this.handleChange}/>
            </FormGroup>
        </Form>
    }
}

export default ParticipantEdit;