import API from './api'


export const USER_NAME_SESSION_ATTRIBUTE_NAME = 'authenticatedUser'

class AuthenticationService {

    executeJwtAuthenticationService(username: string, password: string) {
        return API.getInstance().post("/authenticate", {
            username,
            password
        })
    }


    registerSuccessfulLoginForJwt(username: string, token: string) {
        sessionStorage.setItem(USER_NAME_SESSION_ATTRIBUTE_NAME, username)
        API.getInstance().setupAxiosInterceptors(this.createJWTToken(token))
    }

    createJWTToken(token: string) {
        return 'Bearer ' + token
    }


    logout() {
        sessionStorage.removeItem(USER_NAME_SESSION_ATTRIBUTE_NAME);
        sessionStorage.removeItem("mmbj");
        sessionStorage.removeItem("selectedEvent");
    }

    forgotPassword(email: string): Promise<void> {
        return API.getInstance().get("/api/forgot/" + email);
    }

    async changePassword(email: string, password: string, passwordRe: string, passwordStr: string | undefined): Promise<void> {
        return  API.getInstance().post("/api/change", {
            email: email,
            password: password,
            passwordRe: passwordRe,
            passwordStr: passwordStr
        });
    }

    isUserLoggedIn() {
        const user = sessionStorage.getItem(USER_NAME_SESSION_ATTRIBUTE_NAME)
        if (user === null) return false
        return true
    }

    getLoggedInUserName() {
        const user = sessionStorage.getItem(USER_NAME_SESSION_ATTRIBUTE_NAME)
        if (user === null) return ''
        return user
    }

}

export default new AuthenticationService()