import React, {Fragment} from "react";
import {MultiballParticipant} from "../model/MultiballParticipant";
import {Input} from "reactstrap";
import API from "../service/api";

interface Props {
    invalid: boolean,
    eventId: string | null,
    onChange: any
}

interface State {
    participants: Array<MultiballParticipant>,
    activeSuggestion: number,
    filteredSuggestions: Array<MultiballParticipant>,
    showSuggestions: boolean,
    userInput: string | null
}


class AutocompleteParticipant extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            participants: [],
            // The active selection's index
            activeSuggestion: 0,
            // The suggestions that match the user's input
            filteredSuggestions: [],
            // Whether or not the suggestion list is shown
            showSuggestions: false,
            // What the user has entered
            userInput: ""
        };
    }


    onChange(e: React.ChangeEvent<HTMLInputElement>): void {
        const userInput = e.currentTarget.value;
        if(userInput.length > 0){
            API.getInstance().get(`/api/participant/search/${userInput}/in/${this.props.eventId}`).then(
                (result) => {
                    // Filter our suggestions that don't contain the user's input
                    const filteredSuggestions = result.data;

                    this.setState({
                        activeSuggestion: 0,
                        filteredSuggestions,
                        showSuggestions: true,
                        userInput: userInput
                    });
                }
            )
        } else {
            this.setState({
                activeSuggestion: 0,
                filteredSuggestions:[],
                showSuggestions: false,
                userInput: userInput
            });
        }


    }


    onClick(e: React.MouseEvent<HTMLLIElement, MouseEvent>): void {
        const activeSuggestion: string | undefined = e.currentTarget.dataset.id;
        if(activeSuggestion != undefined){
            const activeIndex = parseInt(activeSuggestion);
            const element = this.state.filteredSuggestions[activeIndex]
            this.props.onChange(element);
            this.setState({
                activeSuggestion: 0,
                showSuggestions: false,
                userInput: ''
            });
        }
    }
    onBlur(): void {
        setTimeout( () => {

            this.setState({
                activeSuggestion: 0,
                showSuggestions: false
            });
        }, 100);
    }

    addNewParticipant(): void {
        this.props.onChange(
            {
                email: null,
                eventId: null,
                id: null,
                name: this.state.userInput,
                nickName: this.state.userInput
            }
        );
    }

    onKeyDown(e: React.KeyboardEvent<HTMLInputElement>): void {
        const {activeSuggestion, filteredSuggestions} = this.state;
        if (e.code === "Escape" && this.state.showSuggestions) {
            this.setState({
                activeSuggestion: 0,
                showSuggestions: false
            });
        }
        // User pressed the enter key
        if (e.code === "Enter") {
            if(this.state.showSuggestions){
                if(filteredSuggestions[activeSuggestion]){
                    this.props.onChange(filteredSuggestions[activeSuggestion]);
                } else {
                    this.addNewParticipant();
                }
            } else {
                this.addNewParticipant();
            }
            this.setState({
                activeSuggestion: 0,
                showSuggestions: false,
                userInput: ''
            });
        }
        // User pressed the up arrow
        else if (e.code === "ArrowUp" && this.state.showSuggestions) {
            if (activeSuggestion === 0) {
                return;
            }

            this.setState({activeSuggestion: activeSuggestion - 1});
        }
        // User pressed the down arrow
        else if (e.code === "ArrowDown" && this.state.showSuggestions) {
            if (activeSuggestion + 1 == filteredSuggestions.length) {
                return;
            }
            this.setState({activeSuggestion: activeSuggestion + 1});
        }

    }

    render(): JSX.Element {
        const {
            state: {
                activeSuggestion,
                filteredSuggestions,
                showSuggestions,
                userInput
            }
        } = this;

        let suggestionsListComponent;

        if (showSuggestions && userInput) {
            if (filteredSuggestions.length) {
                suggestionsListComponent = (
                    <ul className="suggestions">
                        {filteredSuggestions.map((suggestion: MultiballParticipant, index: any) => {
                            let className;

                            // Flag the active suggestion with a class
                            if (index === activeSuggestion) {
                                className = "suggestion-active";
                            }

                            return (
                                <li className={className}
                                    key={index}
                                    data-id={index}
                                    onClick={
                                        (e) => {
                                            this.onClick(e);
                                        }
                                    }

                                >
                                    {suggestion.name} <span style={{float:"right"}}>{suggestion.email}</span>
                                </li>
                            );
                        })}
                    </ul>
                );
            }
        }

        return (
            <Fragment>
                <Input
                    invalid={this.props.invalid}
                    type="text"
                    onChange={this.onChange.bind(this)}
                    onKeyDown={this.onKeyDown.bind(this)}
                    onBlur={this.onBlur.bind(this)}
                    value={userInput || ''}
                />
                {suggestionsListComponent}
            </Fragment>
        );
    }
}

export default AutocompleteParticipant;
