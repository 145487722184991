import React from "react";
import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse} from "axios"
import AuthenticationService, {USER_NAME_SESSION_ATTRIBUTE_NAME} from "./AuthenticationService";

export default class API extends React.PureComponent {
    static instance: API | null = null;

    authToken: string | null = null;

    static getInstance(): API {
        if (API.instance == null) {
            API.instance = new API();
        }
        return API.instance;
    }

    axiosInstance: AxiosInstance;

    constructor() {
        super({});
        this.axiosInstance = axios.create({
         //   baseURL: `https://multiball.mdsz.hu/`,
            baseURL: `https://multiball.mdsz.hu/`,

            headers: {
                'Accept': 'application/json; charset=utf-8',
                'Content-Type': 'application/json; charset=utf-8'
            }
        });
        this.authToken = sessionStorage.getItem("mmbj");
        this.axiosInstance.interceptors.response.use(function (response) {
            // Do something with response data
            return response;
        }, function (error) {
            if (error.response !== undefined) {
                if (error.response.status == 401) {
                    if (!window.location.href.endsWith("/login")) {
                        sessionStorage.removeItem(USER_NAME_SESSION_ATTRIBUTE_NAME);
                        window.location.href = "/";

                    }
                }
            }
            // Do something with response error
            return Promise.reject(error);
        });

    }

    get<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
        config = this.setupConfig(config);
        return this.axiosInstance.get(url, config);
    }

    delete<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
        config = this.setupConfig(config);
        return this.axiosInstance.delete(url, config);
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    post<T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R> {
        config = this.setupConfig(config);
        return this.axiosInstance.post(url, data, config);
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    postNoToken<T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R> {
        return this.axiosInstance.post(url, data, config);
    }


    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    put<T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R> {
        config = this.setupConfig(config);
        return this.axiosInstance.put(url, data, config);
    }

    download(url: string): void {
        const config = this.setupConfig();
        config.responseType = 'blob';
        this.axiosInstance.get(url, config).then((response) => {
            const urlBlob = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = urlBlob;
            link.setAttribute('download', 'event.xlsx');
            document.body.appendChild(link);
            link.click();
        });
    }

    setupConfig(config?: AxiosRequestConfig): AxiosRequestConfig {
        if (config != null) {
            config.withCredentials = true;
            config.headers.authorization = this.authToken;
        } else {
            config = {
                withCredentials: true,
                headers: {
                    authorization: this.authToken
                }
            }
        }
        return config;
    }

    setupAxiosInterceptors(token: string): void {
        this.authToken = token;
        sessionStorage.setItem("mmbj", this.authToken);
        this.axiosInstance.interceptors.request.use(
            (config) => {
                if (AuthenticationService.isUserLoggedIn()) {
                    config.headers.authorization = token
                }
                return config
            }
        )
    }

}
