import React, {RefObject} from 'react'
import {
    Card,
    CardBody,
    CardSubtitle,
    CardTitle,
    Col,
    Container,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Nav,
    NavItem,
    NavLink,
    Row
} from 'reactstrap'
import BootstrapTable, {ColumnDescription} from 'react-bootstrap-table-next'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import {PlusCircle} from 'react-bootstrap-icons'
import moment from 'moment';
import {MultiballGame} from "../model/MultiballGame";
import GameEdit from "./GameEdit";
import API from "../service/api"
import {HashLoader} from "react-spinners";

interface Props {
    eventId: string | null,
    selectGame: (evt: MultiballGame) => void
}

interface State {
    games: Array<MultiballGame>,
    isLoading: boolean,
    showEditor: boolean,
    selectedGame: string | null
}

class GameList extends React.Component<Props, State> {


    gameEditorRef: RefObject<GameEdit>;


    constructor(props: Props) {
        super(props)
        this.state = {games: [], isLoading: true, showEditor: false, selectedGame: null};
        this.remove = this.remove.bind(this)

        this.gameEditorRef = React.createRef();
    }

    componentDidMount(): void {
        if (this.props.eventId !== null) {
            this.setState({isLoading: true})
            this.loadGames();
        }
    }

    loadGames(): void {
        API.getInstance().get(`/api/games/${this.props.eventId}`)
            .then(response => response.data)
            .then(data => this.setState({games: data, isLoading: false}))
    }

    remove(id: string | null): void {
        if (id != null) {
            API.getInstance().delete(`/api/game/${id}`).then(() => {
                const updatedParticipants = [...this.state.games].filter(i => i.id !== id)
                this.setState({games: updatedParticipants})
            })
        }
    }

    select(id: string | null): void {
        if (id != null) {
            API.getInstance().get(`/api/game/select/${id}`).then((response) => {
                this.loadGames();
                this.props.selectGame(response.data);
            });
        }
    }

    showEditor(gameId: string | null): void {
        this.setState({...this.state, showEditor: true, selectedGame: gameId});
    }

    hideEditor(): void {
        this.setState({...this.state, showEditor: false, selectedGame: null});
    }

    handleSubmit(): void {
        const item: MultiballGame | undefined = this.gameEditorRef.current?.getItem();
        if (item != undefined) {
            if (item.id) {
                API.getInstance().put('/api/game/' + item.id, item)
                    .then(() => {
                        this.setState({...this.state, showEditor: false})
                        this.loadGames()
                    })
                    .catch((err) => {
                        this.gameEditorRef.current?.setError(err.response.data)
                    });
            } else {
                API.getInstance().post('/api/game', item)
                    .then(() => {
                        this.setState({...this.state, showEditor: false})
                        this.loadGames()
                    })
                    .catch((err) => {
                        this.gameEditorRef.current?.setError(err.response.data)
                    });
            }
        }
    }


    render(): JSX.Element {
        const games = this.state.games;

        if (this.state.isLoading) {
            return (<div className={'box'}><HashLoader color={'#F0833E'} size={100} loading={true}/></div>);
        }

        const columns: ColumnDescription[] = [
            {
                dataField: 'lbuttons',
                text: '',
                headerStyle: {
                    width: '90px'
                },
                formatter: (_cell: any, row: MultiballGame) => (
                    row.startDateTime?<span></span>:
                    <a className={"function"} color="success" onClick={() => this.select(row.id)}>
                        Indítás
                    </a>

                )
            },
            {
                dataField: 'name',
                text: 'Név',
                sort: true
            }, {
                dataField: 'startDateTime',
                text: 'Játék kezdete',
                sort: true,
                formatter: (_cell: any, row: MultiballGame) => (
                    row.startDateTime ? moment(row.startDateTime).format('YYYY.MM.DD HH:mm') : ''
                )
            },
            {
                dataField: 'buttons',
                text: '',
                headerStyle: {
                    width: '100px'
                },
                formatter: (_cell: any, row: MultiballGame) => (
                    <div>
                        <a className={"function"} onClick={() => {
                            this.showEditor(row.id);
                        }}>Szerkesztés
                        </a><br/>
                        <a className={"function"} color="danger" onClick={() => this.remove(row.id)}>
                            Törlés
                        </a>
                    </div>
                )
            }
        ]


        return (
            <div>
                <Nav navbar={true} className={"page-navbar"}>
                    <Container>
                        <Row fluid>
                            <Col><h3>Játékok</h3></Col>
                            <Col style={{}}>
                                <Nav>
                                    <NavItem className={"ml-auto"}>
                                        <NavLink onClick={() => {
                                            this.showEditor(null);
                                        }}><PlusCircle/><span>Játék létrehozása</span>
                                        </NavLink>
                                    </NavItem>
                                </Nav>

                            </Col>

                        </Row>
                    </Container>
                </Nav>
                <Container fluid>
                    <Row>
                        <Col className={"col-md-12"}>
                            <Card>
                                <CardTitle>
                                    Játékok listája
                                </CardTitle>
                                <CardSubtitle>A kiválasztott esemény játékai. Az indításra kattintva a kapcsolt eszközön
                                    elindul a játék</CardSubtitle>
                                <CardBody>

                                    <BootstrapTable
                                        condensed={true}
                                        data={games}
                                        bootstrap4={true}
                                        striped={true}
                                        hover={true}
                                        keyField='name'
                                        columns={columns}
                                        bordered={false}
                                    />

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>

                <Modal isOpen={this.state.showEditor} size="lg" style={{maxWidth: '1600px', width: '900px'}}>
                    <ModalHeader>
                        {this.state.selectedGame != null ? 'Játék szerkesztése' : 'Játék létrehozása'}
                    </ModalHeader>
                    <ModalBody>
                        <GameEdit ref={this.gameEditorRef}
                                  gameId={this.state.selectedGame}
                                  eventId={this.props.eventId}/>
                    </ModalBody>
                    <ModalFooter>
                        <a className={"button"}
                           color="primary"
                           onClick={this.handleSubmit.bind(this)}>Mentés</a>{' '}
                        <a className={"button"} color="secondary" onClick={this.hideEditor.bind(this)}>Mégsem</a>
                    </ModalFooter>
                </Modal>


            </div>
        )
    }
}

export default GameList;
