import React from 'react';
import {Button, ButtonGroup, Form, FormFeedback, Input, InputGroup, InputGroupAddon, Label} from 'reactstrap';
import moment from 'moment';
import "moment/locale/hu";
import "react-datetime/css/react-datetime.css";
import {MultiballGame} from "../model/MultiballGame";
import AutocompleteParticipant from "./AutocompleteParticipant";
import {MultiballParticipant} from "../model/MultiballParticipant";
import {MultiballTeamMember} from "../model/MultiballTeamMember";
import {XSquare} from "react-bootstrap-icons";
import {MultiballError} from "../model/MultiballError";
import API from "../service/api"


interface State {
    item: MultiballGame;
    errors: Map<string, string>
}

interface Props {
    eventId: string | null,
    gameId: string | null
}

class GameEdit extends React.Component<Props, State> {


    emptyItem: MultiballGame = {
        id: null,
        name: null,
        duration: 120,
        startDateTime: null,
        endDateTime: null,
        speed: 0,
        teamOne: {
            id: null,
            name: null,
            members: []
        },
        teamTwo: {
            id: null,
            name: null,
            members: []
        },
        eventId: null
    };

    state: State;

    constructor(props: Props) {
        super(props);
        this.state = {
            item: {
                ...this.emptyItem,
                eventId: this.props.eventId
            },
            errors: new Map<string, string>()
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    getItem(): MultiballGame {
        return this.state.item;
    }

    setError(err: MultiballError): void {
        if (err.errorMessage) {
            const errors = new Map<string, string>();
            err.errorMessage.forEach(value => {
                errors.set(value.fieldName, value.messageError);
            })
            this.setState({...this.state, errors: errors})
        }
    }

    convertDateTimeToString(jsonDate: string): string {
        return moment(new Date(jsonDate)).format('YYYY-MM-DD HH:mm');
    }


    async componentDidMount(): Promise<void> {
        if (this.props.gameId !== null) {
            const game = await (await API.getInstance().get(`/api/game/${this.props.gameId}`)).data;

            this.setState({...this.state, item: game});
        } else {
            const event = await (await API.getInstance().get(`/api/event/${this.props.eventId}`)).data;
            const {item} = this.state;
            item.name = event.gamesInEvent ? (event.gamesInEvent + 1) + ". játék" : "1. játék";
            item.teamOne.name = '#' + item.name + " KÉK";
            item.teamTwo.name = '#' + item.name + " ZÖLD";
            this.setState({...this.state, item: item});
        }
    }

    /**
     * Rekurzív értékbeállítás
     * @param item
     * @param name
     * @param value
     */
    setValue(item: Record<string, unknown>, name: string, value: unknown): void {
        const n = name.indexOf('.');
        if (n == -1) {
            item[name] = value;
        } else {
            const namex = name.substr(0, n);
            const n1 = namex.indexOf('[');
            if (n1 != -1) {
                const n2 = namex.indexOf(']');
                const arrName = namex.substr(0, n1);
                const arrIndex = namex.substr(n1 + 1, n2 - n1 - 1);
                const arr = item[arrName] as Record<string, unknown>;
                this.setValue(
                    arr[arrIndex] as Record<string, unknown>,
                    name.substr(n + 1),
                    value
                );
            } else {
                this.setValue(
                    item[namex] as Record<string, unknown>,
                    name.substr(n + 1),
                    value
                );
            }

        }
    }

    handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
        const target = event.currentTarget;
        const value = target.value;
        const name: string = target.name;
        if (name === 'name') {
            if (
                this.state.item.teamOne.name == null ||
                this.state.item.teamOne.name == '' ||
                this.state.item.teamOne.name == '#' + this.state.item.name + " KÉK"
            ) {
                this.setValueByName("teamOne.name", '#' + value + " KÉK");
            }
            if (
                this.state.item.teamTwo.name == null ||
                this.state.item.teamTwo.name == '' ||
                this.state.item.teamTwo.name == '#' + this.state.item.name + " ZÖLD"
            ) {
                this.setValueByName("teamTwo.name", '#' + value + " ZÖLD");
            }
        }
        this.setValueByName(name, value);


    }

    handleBlur(event: React.ChangeEvent<HTMLInputElement>): void {
        const target = event.currentTarget;
        const value = target.value;
        const name: string = target.name;
        if (name === 'teamOne.name' && value == "") {
            this.setValueByName("teamOne.name", '#' + this.state.item.name + " KÉK");
        }
        if (name === 'teamTwo.name' && value == "") {
            this.setValueByName("teamTwo.name", '#' + this.state.item.name + " ZÖLD");
        }
    }


    handleClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
        const target = event.currentTarget;
        const value = target.value;
        const name: string = target.name;
        this.setValueByName(name, value);
    }

    setValueByName(name: string, value: unknown): void {
        const item: any = {...this.state.item};
        this.setValue(item, name.toString(), value);
        this.setState({...this.state, item, errors: new Map<string, string>()});
    }

    async handleSubmit(): Promise<any> {
        const {item} = this.state;
        if (item.id) {
            return API.getInstance().put('/api/game/' + item.id, item);
        } else {
            return API.getInstance().post('/api/game', item);
        }


    }

    addParticipantToTeamOne(member: MultiballParticipant): void {

        const {item} = this.state;
        member.eventId = this.props.eventId;
        item.teamOne.members.push(
            {
                id: null,
                participant: member,
                serialNumber: item.teamOne.members.length
            }
        );
        this.setState({...this.state, item, errors: new Map<string, string>()});
    }

    addParticipantToTeamTwo(member: MultiballParticipant): void {
        const {item} = this.state;
        member.eventId = this.props.eventId;
        item.teamTwo.members.push(
            {
                id: null,
                participant: member,
                serialNumber: item.teamTwo.members.length
            }
        );
        this.setState({...this.state, item, errors: new Map<string, string>()});
    }

    removeTeamMemberFromTeamOne(index: number): void {
        const {item} = this.state;
        item.teamOne.members.splice(index, 1);
        this.setState({...this.state, item, errors: new Map<string, string>()});
    }

    removeTeamMemberFromTeamTwo(index: number): void {
        const {item} = this.state;
        item.teamTwo.members.splice(index, 1);
        this.setState({...this.state, item, errors: new Map<string, string>()});
    }

    render(): JSX.Element {
        const {item} = this.state;

        return <Form onSubmit={this.handleSubmit}>
            <div className="row">
                <div className="col-md-5 mb-5">
                    <Label for="name">Játék neve</Label>
                    <Input invalid={this.state.errors.get('name') !== undefined} type="text" name="name" id="name"
                           value={item.name || ''}
                           onChange={this.handleChange}
                    />
                    <FormFeedback>{this.state.errors.get('name')}</FormFeedback>
                </div>
                <div className="col-md-2 mb-2">
                    <Label for="duration">Játék hossza</Label>
                    <InputGroup>

                        <Input invalid={this.state.errors.get('duration') != undefined}
                               type="number" name="duration" id="duration" value={item.duration || ''}
                               onChange={this.handleChange}/>
                        <InputGroupAddon addonType="append">mp</InputGroupAddon>
                        <FormFeedback>{this.state.errors.get('duration')}</FormFeedback>
                    </InputGroup>
                </div>
                <div className="col-md-5 mb-5">
                    <Label for="speed">Mozgó darts táblák</Label><br/>
                    <ButtonGroup toggle>
                        <Button value={0} name="speed" onClick={(e) => {
                            this.handleClick(e);
                        }} active={item.speed == 0}>Nem
                            forog</Button>
                        <Button value={1} name="speed" onClick={(e) => {
                            this.handleClick(e);
                        }} active={item.speed == 1}>1</Button>
                        <Button value={2} name="speed" onClick={(e) => {
                            this.handleClick(e);
                        }} active={item.speed == 2}>2</Button>
                        <Button value={3} name="speed" onClick={(e) => {
                            this.handleClick(e);
                        }} active={item.speed == 3}>3</Button>
                        <Button value={4} name="speed" onClick={(e) => {
                            this.handleClick(e);
                        }} active={item.speed == 4}>4</Button>
                    </ButtonGroup>
                </div>
            </div>

            <div className="row">
                <div className="col-md-6 mb-6">
                    <Label for="teamOne.name">Csapatnév (KÉK)</Label>
                    <Input invalid={this.state.errors.get('teamOne.name') != undefined}
                           type="text" name="teamOne.name" id="teamOne.name" value={item.teamOne.name || ''}
                           onChange={this.handleChange}
                           onBlur={this.handleBlur.bind(this)}
                    />
                    <FormFeedback>{this.state.errors.get('teamOne.name')}</FormFeedback>
                </div>
                <div className="col-md-6 mb-6">
                    <Label for="teamTwo.name">Csapatnév (ZÖLD)</Label>
                    <Input invalid={this.state.errors.get('teamTwo.name') != undefined}
                           type="text" name="teamTwo.name" id="teamTwo.name" value={item.teamTwo.name || ''}
                           onChange={this.handleChange}
                           onBlur={this.handleBlur.bind(this)}
                    />
                    <FormFeedback>{this.state.errors.get('teamTwo.name')}</FormFeedback>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 mb-6">
                    <Label for="teamOne.name">Játékos hozzáadása</Label><br/>
                    <AutocompleteParticipant invalid={this.state.errors.get('teamOne.members') != undefined}
                                             eventId={this.props.eventId}
                                             onChange={this.addParticipantToTeamOne.bind(this)}/>

                    <FormFeedback>{this.state.errors.get('teamOne.members')}</FormFeedback>
                </div>
                <div className="col-md-6 mb-6">
                    <Label for="teamOne.name">Játékos hozzáadása</Label><br/>
                    <AutocompleteParticipant invalid={this.state.errors.get('teamTwo.members') != undefined}
                                             eventId={this.props.eventId}
                                             onChange={this.addParticipantToTeamTwo.bind(this)}/>
                    <FormFeedback>{this.state.errors.get('teamTwo.members')}</FormFeedback>
                </div>
            </div>

            <div className="row">
                <div className="col-md-6 mb-6">
                    {
                        item.teamOne.members.map(
                            (member: MultiballTeamMember, index: any) => {
                                if (member.participant != null) {
                                    return (
                                        <div style={{
                                            padding: 5,
                                            overflow: "hidden",
                                            border: '1px lightgray solid',
                                            display: 'block'
                                        }}
                                             key={index}>
                                            <div className="row">
                                                <div className="col-md-11 mb-11">
                                                    <Input
                                                        invalid={this.state.errors.get('teamOne.members[' + index + '].participant.name') != undefined}
                                                        type="text" value={member.participant.name || ''}
                                                        disabled={true}
                                                        onBlur={this.handleBlur.bind(this)}
                                                    />
                                                    <FormFeedback>{this.state.errors.get('teamOne.members[' + index + '].participant.name')}</FormFeedback>
                                                    <Input
                                                        invalid={this.state.errors.get('teamOne.members[' + index + '].participant.email') != undefined}
                                                        type="text"
                                                        name={'teamOne.members[' + index + '].participant.email'}
                                                        value={member.participant.email || ''}
                                                        disabled={member.participant.id != null}
                                                        onChange={this.handleChange}
                                                        onBlur={this.handleBlur.bind(this)}
                                                    />
                                                    <FormFeedback>{this.state.errors.get('teamOne.members[' + index + '].participant.email')}</FormFeedback>
                                                </div>
                                                <div className="col-md-1 mb-1">
                                                    <a className={"button"}  style={{float: 'right'}}
                                                            color="danger"
                                                            onClick={() => this.removeTeamMemberFromTeamOne(index)}>
                                                        <XSquare/>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }
                            }
                        )
                    }
                </div>
                <div className="col-md-6 mb-6">
                    {
                        item.teamTwo.members.map(
                            (member: MultiballTeamMember, index: any) => {
                                if (member.participant != null) {
                                    return (
                                        <div style={{
                                            padding: 5,
                                            overflow: "hidden",
                                            border: '1px lightgray solid',
                                            display: 'block'
                                        }} key={index}>
                                            <div className="row">
                                                <div className="col-md-11 mb-11">
                                                    <Input
                                                        invalid={this.state.errors.get('teamTwo.members[' + index + '].participant.name') != undefined}
                                                        type="text" value={member.participant.name || ''}
                                                        disabled={true}
                                                        onBlur={this.handleBlur.bind(this)}
                                                    />
                                                    <FormFeedback>{this.state.errors.get('teamTwo.members[' + index + '].participant.name')}</FormFeedback>
                                                    <Input
                                                        invalid={this.state.errors.get('teamTwo.members[' + index + '].participant.email') != undefined}
                                                        type="text"
                                                        name={'teamTwo.members[' + index + '].participant.email'}
                                                        value={member.participant.email || ''}
                                                        disabled={member.participant.id != null}
                                                        onChange={this.handleChange}
                                                        onBlur={this.handleBlur.bind(this)}
                                                    />
                                                    <FormFeedback>{this.state.errors.get('teamTwo.members[' + index + '].participant.email')}</FormFeedback>
                                                </div>
                                                <div className="col-md-1 mb-1">
                                                    <a className={"button"} style={{float: 'right'}}
                                                            color="danger"
                                                            onClick={() => this.removeTeamMemberFromTeamTwo(index)}>
                                                        <XSquare/>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }
                            }
                        )
                    }
                </div>
            </div>
        </Form>
    }
}

export default GameEdit;