import React from 'react'
import AuthenticationService from '../service/AuthenticationService';
import {Card, CardBody, CardTitle, Container, Form, FormGroup, Input, Label} from "reactstrap";
import {Redirect} from "react-router-dom";

import logo from "../images/mdsz.png";

interface State {
    [key: string]: any,

    username: string,
    password: string,
    hasLoginFailed: boolean,
    showSuccessMessage: boolean
}

interface Props {
    history: any,
    loginSuccess: () => void;
}

class LoginComponent extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props)

        this.state = {
            username: '',
            password: '',
            hasLoginFailed: false,
            showSuccessMessage: false
        }

        this.handleChange = this.handleChange.bind(this)
        this.loginClicked = this.loginClicked.bind(this)
        //előző munkamenetből ha maradt valami, távolítsuk el
        AuthenticationService.logout();
    }

    handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState(
            {
                ...this.state,
                [event.currentTarget.name]: event.currentTarget.value
            }
        )
    }

    loginClicked(): void {
        AuthenticationService
            .executeJwtAuthenticationService(this.state.username, this.state.password)
            .then(
                (response) => {
                    AuthenticationService.registerSuccessfulLoginForJwt(this.state.username, response.data.token)
                    this.setState({...this.state, showSuccessMessage: true, hasLoginFailed: false})
                    this.props.loginSuccess();
                }
            ).catch(
            () => {
                this.setState({...this.state, showSuccessMessage: false, hasLoginFailed: true})
            }
        )
    }

    render(): JSX.Element {
        return (

            <Container fluid style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%"
            }}>
                <Container style={{width: 600, height: 300, display: "flex", marginBottom:160}}>
                    <div className="logo d-flex align-items-start justify-content-center"
                        style={{width: 150, backgroundColor: "orange", paddingTop: 15}}>
                        <a href="/" className="simple-text logo-mini mx-1">
                            <div className="logo-img"><img
                                src={logo}
                                alt="..."/>
                            </div>
                        </a>
                    </div>
                    <Card>
                        <CardTitle>
                            Bejelentkezés
                        </CardTitle>
                        <CardBody>


                            <div className="container">

                                <Form>
                                    <FormGroup className="col-md-12 mb-12">
                                        <Label for="name">Felhasználó név</Label>
                                        <Input type="text" name="username" value={this.state.username}
                                               onChange={this.handleChange}
                                               autoComplete="username"
                                        />
                                    </FormGroup>
                                    <FormGroup className="col-md-12 mb-12">
                                        <Label for="name">Jelszó</Label>
                                        <Input type="password" name="password" value={this.state.password}
                                               onChange={this.handleChange}
                                               autoComplete="current-password"
                                        />
                                    </FormGroup>
                                    <FormGroup className="col-md-12 mb-12"
                                        style={{marginTop: 40}}>
                                        <a className="button"
                                           onClick={this.loginClicked}>Bejelentkezés</a>
                                        <a className={"button"} href={"/forgot"}>Elfelejtett jelszó</a>
                                    </FormGroup>
                                </Form>

                                {this.state.hasLoginFailed &&
                                <div className="alert alert-warning">Hibás felhasználó név vagy jelszó</div>}
                                {this.state.showSuccessMessage && <Redirect to='/events'/>}
                            </div>

                        </CardBody>
                    </Card>

                </Container>

            </Container>

        )
    }
}

export default LoginComponent