import React, {RefObject} from 'react'
import {
    Card,
    CardBody,
    CardSubtitle,
    CardTitle,
    Col,
    Container,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Nav,
    NavItem,
    NavLink,
    Row
} from 'reactstrap'
import BootstrapTable, {ColumnDescription} from 'react-bootstrap-table-next'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import {ClipboardPlus} from 'react-bootstrap-icons'
import {MultiballEvent} from "../model/MultiballEvent";
import moment from 'moment';
import API from "../service/api";
import EventEdit from "./EventEdit";
import {HashLoader} from "react-spinners";

interface State {
    events: Array<MultiballEvent>,
    isLoading: boolean,
    showDelete: boolean,
    showEditor: boolean,
    eventToDelete: MultiballEvent | null,
    selectedEvent: string | null,
}

interface Props {
    selectEvent: (evt: MultiballEvent) => void;
    addAlert: (mode: string, title: string, message: string) => void;
    eventDeleted: (id: string) => void;
}

class EventList extends React.Component<Props, State> {

    eventEditorRef: RefObject<EventEdit>;

    constructor(props: Props) {
        super(props)
        this.state = {
            events: [],
            isLoading: true,
            showDelete: false,
            showEditor: false,
            eventToDelete: null,
            selectedEvent: null
        };
        this.eventEditorRef = React.createRef();
    }

    componentDidMount(): void {
        this.setState({isLoading: true})

        API.getInstance().get('api/events')
            .then(axiosResponse => this.setState({events: axiosResponse.data, isLoading: false}))
    }

    processDelete(): void {
        if (this.state.eventToDelete != null) {
            API.getInstance().delete(`/api/event/${this.state.eventToDelete.id}`).then(() => {
                if(this.state.eventToDelete != null && this.state.eventToDelete.id != null){
                    this.props.eventDeleted(this.state.eventToDelete.id);
                }
                const updatedEvents = [...this.state.events].filter(i => i.id !== this.state.eventToDelete?.id)
                this.setState({...this.state, events: updatedEvents})
                this.hideDelete();
            }).catch(
                () => this.props.addAlert("error", "Hiba történt!", "Az esemény nem törölhető")
            );
        }
    }

    showDelete(event: MultiballEvent | null): void {
        this.setState({...this.state, showDelete: true, eventToDelete: event})
    }

    hideDelete(): void {
        this.setState({...this.state, showDelete: false})
    }

    showEditor(gameId: string | null): void {
        this.setState({...this.state, showEditor: true, selectedEvent: gameId});
    }

    hideEditor(): void {
        this.setState({...this.state, showEditor: false, selectedEvent: null});
    }

    select(evt: MultiballEvent): void {
        this.props.selectEvent(evt);
    }


    handleSubmit(): void {
        const item: MultiballEvent | undefined = this.eventEditorRef.current?.getItem();
        if (item != undefined) {


            if (item.id) {
                API.getInstance().put('/api/event/' + item.id, item).then(() => {
                    window.location.href = "/events";
                }).catch((error) => {
                    this.eventEditorRef.current?.setError(error.response.data);
                });
            } else {
                API.getInstance().post('/api/event', item).then(() => {
                    window.location.href = "/events";
                }).catch((error) => {
                    this.eventEditorRef.current?.setError(error.response.data);
                });
            }
        }
    }

    render(): JSX.Element {
        const events = this.state.events;

        if (this.state.isLoading) {
            return (<div className={'box'}><HashLoader color={'#F0833E'} size={100} loading={true}/></div>);
        }

        const columns: ColumnDescription[] = [

            {
                dataField: 'selector',
                text: '',
                headerStyle: {width: '100px'},
                formatter: (_cell: any, row: MultiballEvent) => (

                    <a className={"function"} onClick={() => this.props.selectEvent(row)}>
                        Kiválasztás
                    </a>

                )
            },

            {
                dataField: 'name',
                text: 'Esemény neve',
                sort: true,
                headerStyle: {},
            }, {
                dataField: 'locationName',
                text: 'Helyszín',
                sort: true
            }, {
                dataField: 'startDateTime',
                text: 'Kezdet',
                sort: true,
                headerStyle: {width: '150px'},
                formatter: (_cell: any, row: MultiballEvent) => (
                    moment(row.startDateTime).format('YYYY.MM.DD HH:mm')
                )
            },
            {
                dataField: 'buttons',
                text: '',
                headerStyle: {width: '100px'},
                formatter: (_cell: any, row: MultiballEvent) => (
                    <div>
                        <a className={"function"} onClick={() => this.showEditor(row.id)}>
                            Szerkesztés
                        </a><br/>
                        <a className={"function"} onClick={() => this.showDelete(row)}>
                            Törlés
                        </a>
                    </div>
                )
            }
        ]

        return (
            <div>
                <Nav navbar={true} className={"page-navbar"}>
                    <Container>
                        <Row fluid>
                            <Col><h3>Események</h3></Col>
                            <Col style={{}}>
                                <Nav>
                                    <NavItem className={"ml-auto"}>
                                        <NavLink onClick={() => {
                                            this.showEditor(null);
                                        }}><ClipboardPlus/><span>Esemény létrehozása</span>
                                        </NavLink>
                                    </NavItem>
                                </Nav>

                            </Col>

                        </Row>
                    </Container>
                </Nav>
                <Container fluid >
                    <Row>
                        <Col className={"col-md-12"}>
                            <Card>
                                <CardTitle>
                                    Események listája
                                </CardTitle>
                                <CardSubtitle>A kiválasztásra kattintva a kiválasztott esemény elemeit
                                    szerkesztheti a megjelenő menüpontokon keresztül</CardSubtitle>
                                <CardBody>

                                    <BootstrapTable
                                        condensed={true}
                                        data={events}
                                        bootstrap4={true}
                                        striped={true}
                                        hover={true}
                                        keyField='id'
                                        columns={columns}
                                        bordered={false}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
                <Modal isOpen={this.state.showDelete} size="lg">
                    <ModalHeader>Esemény törlése</ModalHeader>
                    <ModalBody>
                        A kiválasztott esemény neve: <b>{this.state.eventToDelete?.name}</b>.
                        <br/>
                        Biztosan törli az eseményt? </ModalBody>
                    <ModalFooter>
                        <a className={"button"} color="danger"
                           onClick={this.processDelete.bind(this)}>Törlés</a>
                        <a className={"button"} color="secondary" onClick={this.hideDelete.bind(this)}>Mégsem</a>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={this.state.showEditor} size="lg" style={{maxWidth: '1600px', width: '900px'}}>
                    <ModalHeader>
                        {this.state.selectedEvent != null ? 'Esemény szerkesztése' : 'Esemény létrehozása'}
                    </ModalHeader>
                    <ModalBody>
                        <EventEdit ref={this.eventEditorRef}
                                   eventId={this.state.selectedEvent}/>
                    </ModalBody>
                    <ModalFooter>
                        <a className={"button"} color="primary"
                           onClick={this.handleSubmit.bind(this)}>Mentés</a>{' '}
                        <a className={"button"} color="secondary" onClick={this.hideEditor.bind(this)}>Mégsem</a>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default EventList;
