import React from 'react'
import {Nav, NavItem, NavLink, Progress} from 'reactstrap'
import {Clipboard, Controller, DoorOpen, FileEarmarkExcel, People, Shield} from "react-bootstrap-icons";

import AuthenticationService from "./service/AuthenticationService";
import {MultiballEvent} from "./model/MultiballEvent";
import {MultiballGame} from "./model/MultiballGame";
import API from "./service/api";

interface Props {
    selectedEvent: MultiballEvent | null,
    selectedGame: MultiballGame | null,
    selectGame: (evt: MultiballGame | null) => void,
    logout: () => void
}

interface State {
    isOpen: boolean,
    counter: number
}

export default class AppNavbar extends React.Component<Props, State> {

    interval: NodeJS.Timeout | null = null;

    constructor(props: Props) {
        super(props)
        this.state = {isOpen: false, counter: 0};
        this.toggle = this.toggle.bind(this)
    }

    toggle(): void {
        this.setState({
            isOpen: !this.state.isOpen,
            counter: 0
        })
    }

    componentWillUnmount(): void {
        this.clearInterval();
    }

    clearSelectedGame(): void {
        if (this.props.selectedGame != null) {
            API.getInstance().get("/api/game/deselect/" + this.props.selectedGame.id).then(
                () => {
                    this.props.selectGame(null);
                }
            ).catch()
        }
    }

    clearInterval(): void {
        if (this.interval != null) {
            clearInterval(this.interval);
            this.interval = null;
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (prevProps.selectedGame !== this.props.selectedGame) {
            if (this.props.selectedGame != null) {
                this.setState({...this.state, counter: 0});

                this.clearInterval();
                this.interval = setInterval(this.tick.bind(this), 1000);
            } else {
                this.clearInterval();
            }
        }
    }

    tick(): void {
        let counter = this.state.counter;
        if (this.props.selectedGame != null && this.props.selectedGame.duration != null) {
            if (counter < this.props.selectedGame.duration + 10) {
                counter += 1;
                this.setState({...this.state, counter: counter});
            } else {
                this.clearSelectedGame();
                this.clearInterval();
            }
        }
    }

    render(): JSX.Element {
        return <Nav vertical color="light" className="list-unstyled pb-3">


            {this.props.selectedGame != null &&

            <Progress
                max={this.props.selectedGame.duration != null ? this.props.selectedGame.duration + 10 : 0}
                min={0}
                color={"red"}
                value={this.state.counter}
                style={{width: "100%", height: 2, position: "absolute"}}

            />
            }
            {AuthenticationService.isUserLoggedIn() &&
            <Nav navbar>
                <NavItem>
                    <NavLink href="/events"><Clipboard size={28}/><p>Események</p></NavLink>
                </NavItem>


                {this.props.selectedEvent != null &&
                <NavItem className={"active"} >
                    <NavLink style={{textAlign: "center"}}>
                        {this.props.selectedEvent?.name}
                    </NavLink>
                </NavItem>
                }
                {this.props.selectedEvent != null &&

                <NavItem>
                    <NavLink href={`/participants/${this.props.selectedEvent?.id}`}>
                        <People size={28}/><p>Résztvevők</p>
                    </NavLink>
                </NavItem>
                }
                {this.props.selectedEvent != null &&
                <NavItem>
                    <NavLink href={`/games/${this.props.selectedEvent?.id}`}>
                        <Controller size={28}/><p>Játékok</p>
                    </NavLink>
                </NavItem>
                }

                {this.props.selectedEvent != null &&
                <NavItem>
                    <NavLink onClick={() => {
                        API.getInstance().download('/api/excel/export/' + this.props.selectedEvent?.id)
                    }}>
                        <FileEarmarkExcel size={28}/><p>Adatok exportálása</p>
                    </NavLink>
                </NavItem>
                }

                <hr style={{backgroundColor: "white", width: 200}}/>
                <NavItem>
                    <NavLink href="/changePassword/"><Shield size={28}/><p>Jelszó változtatás</p></NavLink>
                </NavItem>

                <NavItem>
                    <NavLink onClick={this.props.logout}><DoorOpen size={28}/><p>Kijelentkezés</p></NavLink>
                </NavItem>

            </Nav>
            }
        </Nav>
    }
}
