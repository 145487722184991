import React, {RefObject} from 'react'
import {
    Card,
    CardBody,
    CardSubtitle,
    CardTitle,
    Col,
    Container,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Nav,
    NavItem,
    NavLink,
    Row
} from 'reactstrap'
import BootstrapTable, {ColumnDescription} from 'react-bootstrap-table-next'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import {FilePlus, PersonPlus} from 'react-bootstrap-icons'
import ParticipantEdit from "./ParticipantEdit";
import {MultiballParticipant} from "../model/MultiballParticipant";
import API from "../service/api";
import {HashLoader} from "react-spinners";

interface Props {
    eventId: string | null,
    addAlert: (mode: string, title: string, message: string) => void;
}

interface State {
    participants: Array<MultiballParticipant>,
    isLoading: boolean,
    showEditor: boolean,
    selectedParticipant: null | string,
    participantToDelete: MultiballParticipant | null,
    showDelete: boolean
}

class ParticipantList extends React.Component<Props, State> {

    participantEditorRef: RefObject<ParticipantEdit>;
    hiddenFileInput: RefObject<HTMLInputElement>;

    constructor(props: Props) {
        super(props)
        this.state = {
            participants: [],
            isLoading: true,
            showEditor: false,
            selectedParticipant: null,
            participantToDelete: null,
            showDelete: false
        };
        this.participantEditorRef = React.createRef();
        this.hiddenFileInput = React.createRef();
    }

    componentDidMount(): void {
        this.setState({isLoading: true})
        this.loadParticipants();
    }

    loadParticipants(): void {
        API.getInstance().get(`/api/participants/${this.props.eventId}`)
            .then(response => response.data)
            .then(data => this.setState({participants: data, isLoading: false, showEditor: false}));
    }

    showEditor(participantId: string | null): void {
        this.setState({...this.state, showEditor: true, selectedParticipant: participantId});
    }

    hideEditor(): void {
        this.setState({...this.state, showEditor: false, selectedParticipant: null});
    }

    handleSubmit(): void {
        this.participantEditorRef.current?.handleSubmit().then(() => {
            API.getInstance().get(`/api/participants/${this.props.eventId}`)
                .then(response => response.data)
                .then(data => this.setState({participants: data, isLoading: false, showEditor: false}))
            this.hideEditor();
        }).catch(
            (err) => {
                this.participantEditorRef.current?.setError(err.response.data)
            }
        );
    }

    uploadExcelFile(event: React.ChangeEvent<HTMLInputElement>): void {
        event.preventDefault();
        const formData = new FormData();
        const {files} = event.target;
        if (files != null) {
            for (let fileIndex = 0; fileIndex < files.length; fileIndex++) {
                formData.append('file', files[fileIndex]);
            }
            API.getInstance().post(`/api/excel/upload/${this.props.eventId}`, formData)
                .then(response => response.data)
                .then(() => {
                    if (this.hiddenFileInput.current != null) {
                        this.hiddenFileInput.current.value = '';
                    }
                    this.loadParticipants();
                })
                .catch((_error) => {
                    console.log('error occurred!', _error);

                    if (this.hiddenFileInput.current != null) {
                        this.hiddenFileInput.current.value = '';
                    }
                });
        }
    }

    processDelete(): void {
        if (this.state.participantToDelete != null) {
            API.getInstance().delete(`/api/participant/${this.state.participantToDelete.id}`
            ).then(() => {
                const updatedParticipants = [...this.state.participants].filter(i => i.id !== this.state.participantToDelete?.id)
                this.setState({...this.state, participants: updatedParticipants})
                this.hideDelete();
            }).catch(
                () => {
                    this.props.addAlert("error", "Hiba történt!", "Az résztvevő nem törölhető");
                    this.hideDelete();
                }
            );
        }
    }

    showDelete(participant: MultiballParticipant | null): void {
        this.setState({...this.state, showDelete: true, participantToDelete: participant})
    }

    hideDelete(): void {
        this.setState({...this.state, showDelete: false})
    }

    uploadClick(): void {
        this.hiddenFileInput.current?.click();
    }

    render(): JSX.Element {
        const participants = this.state.participants;

        if (this.state.isLoading) {
            return (<div className={'box'}><HashLoader color={'#F0833E'} size={100} loading={true}/></div>);
        }

        const columns: ColumnDescription[] = [
            {
                dataField: 'name',
                text: 'Név',
                sort: true
            }, {
                dataField: 'email',
                text: 'E-mail',
                sort: true
            }, {
                dataField: 'nickName',
                text: 'Becenév',
                sort: true
            },
            {
                dataField: 'buttons',
                text: '',
                headerStyle: {
                    width: '100px'
                },
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                formatter: (_cell: any, row: MultiballParticipant, _rowIndex: number) => (
                    <div>
                        <a className={"function"} onClick={() => {
                            this.showEditor(row.id);
                        }}>
                            Szerkesztés
                        </a><br/>
                        <a className={"function"} onClick={() => this.showDelete(row)}>
                            Törlés
                        </a>

                    </div>
                )
            }
        ]

        return (
            <div>
                <Nav navbar={true} className={"page-navbar"}>
                    <Container>
                        <Row fluid>
                            <Col className={"col-md-1"}><h3>Résztvevők</h3></Col>
                            <Col style={{}}>
                                <Nav>
                                    <NavItem className={"ml-auto"} style={{marginRight: 10}}>
                                        <NavLink onClick={() => {
                                            this.uploadClick();
                                        }}><FilePlus/><span>Résztvevők feltöltése </span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink onClick={() => {
                                            this.showEditor(null);
                                        }}><PersonPlus/><span>Résztvevő létrehozása</span>
                                        </NavLink>
                                    </NavItem>
                                </Nav>

                            </Col>

                        </Row>
                    </Container>
                </Nav>
                <Container fluid>

                    <Container fluid>
                        <input style={{display: 'none'}}
                               ref={this.hiddenFileInput}
                               type="file"
                               accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                               onChange={(event) => this.uploadExcelFile(event)}
                        />
                    </Container>
                    <Row>
                        <Col className={"col-md-12"}>
                            <Card>
                                <CardTitle>
                                    Résztvevők listája
                                </CardTitle>
                                <CardSubtitle>A kiválasztott esemény résztvevői.</CardSubtitle>
                                <CardBody>

                                    <BootstrapTable
                                        condensed={true}
                                        data={participants}
                                        bootstrap4={true}
                                        striped={true}
                                        hover={true}
                                        keyField='id'
                                        columns={columns}
                                        bordered={false}
                                    />

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>


                <Modal isOpen={this.state.showEditor}>
                    <ModalHeader>
                        {this.state.selectedParticipant != null ? 'Résztvevő szerkesztése' : 'Résztvevő létrehozása'}
                    </ModalHeader>
                    <ModalBody>
                        <ParticipantEdit ref={this.participantEditorRef}
                                         participantId={this.state.selectedParticipant}
                                         eventId={this.props.eventId}/>
                    </ModalBody>
                    <ModalFooter>
                        <a className={"button"} color="primary"
                           onClick={this.handleSubmit.bind(this)}>Mentés</a>{' '}
                        <a className={"button"} color="secondary" onClick={this.hideEditor.bind(this)}>Mégsem</a>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={this.state.showDelete} size="lg">
                    <ModalHeader>Résztvevő törlése</ModalHeader>
                    <ModalBody>
                        A kiválasztott résztvevő neve: <b>{this.state.participantToDelete?.name}</b>.
                        <br/>
                        Biztosan törli a résztvevőt? </ModalBody>
                    <ModalFooter>
                        <a className={"button"} color="danger"
                           onClick={this.processDelete.bind(this)}>Törlés</a>{' '}
                        <a className={"button"} color="secondary" onClick={this.hideDelete.bind(this)}>Mégsem</a>
                    </ModalFooter>
                </Modal>


            </div>
        )
    }

}

export default ParticipantList;
