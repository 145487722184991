import React from 'react';
import {RouteComponentProps} from 'react-router-dom';
import {Container, Form, FormFeedback, FormGroup, Input, Label} from 'reactstrap';
import moment from 'moment';
import "moment/locale/hu";
import "react-datetime/css/react-datetime.css";
import {MultiballEvent} from "../model/MultiballEvent";
import {StaticContext} from "react-router";
import API from "../service/api"
import {MultiballError} from "../model/MultiballError";

interface State {
    item: MultiballEvent;
    errors: Errors;
}

interface Errors {
    [key: string]: any,

    name: string | null,
    startDateTime: string | null,
    endDateTime: string | null,
    locationName: string | null,
    postalCode: string | null,
    settlement: string | null,
    street: string | null,
    streetNumber: string | null,
}

class EventEdit extends React.Component<any, State> {

    emptyItem: MultiballEvent = {
        id: null,
        locationName: null,
        name: null,
        postalCode: null,
        remark: null,
        settlement: null,
        startDateTime: null,
        startDate: null,
        startTime: null,
        endDateTime: null,
        endDate: null,
        endTime: null,
        street: null,
        streetNumber: null,
        gamesInEvent: 0
    };

    createEmptyErrors(): Errors {
        return {
            name: null,
            startDateTime: null,
            endDateTime: null,
            locationName: null,
            postalCode: null,
            settlement: null,
            street: null,
            streetNumber: null,
        };
    }

    state: State;

    constructor(props: RouteComponentProps<any, StaticContext, unknown>) {
        super(props);
        this.state = {
            item: this.emptyItem,
            errors: this.createEmptyErrors()
        };
        this.handleChange = this.handleChange.bind(this);
    }

    convertDateToString(jsonDate: string): string {
        return moment(new Date(jsonDate)).format('YYYY-MM-DD');
    }

    convertTimeToString(jsonDate: string): string {
        return moment(new Date(jsonDate)).format('HH:mm');
    }

    convertToDateString(date: Date): string {
        return moment(date).format('YYYY-MM-DD');
    }

    convertToTimeString(date: Date): string {
        return moment(date).format('HH:mm');
    }

    getItem(): MultiballEvent {
        return this.state.item;
    }

    async componentDidMount(): Promise<void> {
        if (this.props.eventId !== null) {
            const event = await (await API.getInstance().get(`/api/event/${this.props.eventId}`)).data;
            event.startDate = this.convertDateToString(event.startDateTime);
            event.startTime = this.convertTimeToString(event.startDateTime);
            event.endDate = this.convertDateToString(event.endDateTime);
            event.endTime = this.convertTimeToString(event.endDateTime);
            this.setState({item: event});
        } else {
            const now = new Date();
            const end = moment(now).add(4, 'hours').toDate();
            const newItem = {
                ...this.emptyItem,
                startDate: this.convertToDateString(now),
                endDate: this.convertToDateString(end),
                startTime: this.convertToTimeString(now),
                endTime: this.convertToTimeString(end)
            };
            newItem['startDateTime'] = newItem['startDate'] + 'T' + newItem['startTime'] + ':00.000+01:00';
            newItem['endDateTime'] = newItem['endDate'] + 'T' + newItem['endTime'] + ':00.000+01:00';
            this.setState({item: newItem});
        }
    }

    handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
        const target = event.target;
        const value = target.value;
        const name: string = target.name;
        const item: any = {...this.state.item};
        item[name.toString()] = value;
        if (name === 'startDate' || name === 'startTime') {
            item['startDateTime'] = item['startDate'] + 'T' + item['startTime'] + ':00.000+01:00';
        }
        if (name === 'endDate' || name === 'endTime') {
            item['endDateTime'] = item['endDate'] + 'T' + item['endTime'] + ':00.000+01:00';
        }
        this.setState({item});
    }


    setError(err: MultiballError): void {
        if (err.errorMessage) {
            const errors = this.createEmptyErrors();
            err.errorMessage.forEach(value => {
                errors[value.fieldName] = value.messageError;
            })
            this.setState({...this.state, errors: errors})
        } else {
            //TODO internal error?
        }
    }

    render(): JSX.Element {
        const {item} = this.state;
        return <div>
            <Container>
                <Form>
                    <FormGroup>
                        <Label for="name">Esemény neve</Label>
                        <Input invalid={this.state.errors.name != null}
                               type="text" name="name" id="name" value={item.name || ''}
                               onChange={this.handleChange}/>
                        <FormFeedback>{this.state.errors.name}</FormFeedback>
                    </FormGroup>

                    <div className="row">
                        <FormGroup className="col-md-4 mb-4">
                            <Label for="startDate">Esemény kezdődátuma</Label>
                            <Input invalid={this.state.errors.startDateTime != null}
                                   type="date" name="startDate" id="startDate" value={item.startDate || ''}
                                   onChange={this.handleChange}/>
                            <FormFeedback>{this.state.errors.startDateTime}</FormFeedback>
                        </FormGroup>
                        <FormGroup className="col-md-2 mb-2">
                            <Label for="startTime">Időpontja</Label>
                            <Input type="time" name="startTime" id="startTime" value={item.startTime || ''}
                                   onChange={this.handleChange}/>
                        </FormGroup>
                        <FormGroup className="col-md-4 mb-4">
                            <Label for="endDate">Esemény végdátuma</Label>
                            <Input invalid={this.state.errors.endDateTime != null}
                                   type="date" name="endDate" id="endDate" value={item.endDate || ''}
                                   onChange={this.handleChange}/>
                            <FormFeedback>{this.state.errors.endDateTime}</FormFeedback>
                        </FormGroup>
                        <FormGroup className="col-md-2 mb-2">
                            <Label for="endTime">Időpontja</Label>
                            <Input type="time" name="endTime" id="endTime" value={item.endTime || ''}
                                   onChange={this.handleChange}/>
                        </FormGroup>
                    </div>

                    <FormGroup>
                        <Label for="locationName">Helyszín neve</Label>
                        <Input invalid={this.state.errors.locationName != null}
                               type="text" name="locationName" id="locationName" value={item.locationName || ''}
                               onChange={this.handleChange}/>
                        <FormFeedback>{this.state.errors.locationName}</FormFeedback>
                    </FormGroup>

                    <div className="row">
                        <FormGroup className="col-md-2 mb-2">
                            <Label for="postalCode">Irányítószám</Label>
                            <Input invalid={this.state.errors.postalCode != null}
                                   type="text" name="postalCode" id="postalCode" value={item.postalCode || ''}
                                   onChange={this.handleChange}/>
                            <FormFeedback>{this.state.errors.postalCode}</FormFeedback>
                        </FormGroup>
                        <FormGroup className="col-md-4 mb-4">
                            <Label for="settlement">Település</Label>
                            <Input invalid={this.state.errors.settlement != null}
                                   type="text" name="settlement" id="settlement" value={item.settlement || ''}
                                   onChange={this.handleChange}/>
                            <FormFeedback>{this.state.errors.settlement}</FormFeedback>
                        </FormGroup>
                        <FormGroup className="col-md-4 mb-4">
                            <Label for="street">Utca</Label>
                            <Input invalid={this.state.errors.street != null}
                                   type="text" name="street" id="street" value={item.street || ''}
                                   onChange={this.handleChange} autoComplete="address-level1"/>
                            <FormFeedback>{this.state.errors.street}</FormFeedback>
                        </FormGroup>
                        <FormGroup className="col-md-2 mb-2">
                            <Label for="streetNumber">Házszám</Label>
                            <Input invalid={this.state.errors.streetNumber != null}
                                   type="text" name="streetNumber" id="streetNumber" value={item.streetNumber || ''}
                                   onChange={this.handleChange} autoComplete="address-level1"/>
                            <FormFeedback>{this.state.errors.streetNumber}</FormFeedback>
                        </FormGroup>
                    </div>
                    <FormGroup>
                        <Label for="remark">Megjegyzés</Label>
                        <Input type="text" name="remark" id="remark" value={item.remark || ''}
                               onChange={this.handleChange}/>
                    </FormGroup>

                </Form>
            </Container>
        </div>
    }
}

export default EventEdit;